<template>
  <section class="mt-16 financial-consulting-parent">
    <v-row class="mt-16">
      <v-col cols="12">
        <!-- Body -->
        <v-row class="body mt-5" v-if="!isLoading">
          <v-col cols="12" md="9" class="px-16 col-class">
            <v-breadcrumbs class="mb-5" :items="items" large></v-breadcrumbs>
            <div class="card-main-contenet pa-0 ma-0">
              <v-card class="card-class rounded-lg shadow-none mx-auto" height="250">
                <div class="overlay"></div>
                <v-img :src="endPoint(form?.image) + form?.image" class="main-image"></v-img>
                <div class="detail">
                  <v-app-bar flat color="rgba(0, 0, 0, 0)" class="app-bar">
                    <span class="cat-type">{{ form[`name_${$i18n.locale}`] }}</span>
                  </v-app-bar>
                  <v-card-text class="pa-0 ma-0   shadow-none">
                    <div class="font-weight-bold">
                      <h3 class="financial mt-13">{{ form[`title_${$i18n.locale}`] }}</h3>
                    </div>
                  </v-card-text>
                </div>
              </v-card>
            </div>

            <!-- description -->
            <section :class="{ 'read-more': !readMore }">
              <div class="mt-16" v-html="form[`description_${$i18n.locale}`]" />
            </section>

            <section class="my-10">
              <v-divider></v-divider>
              <span class="d-flex justify-center mt-n5">
                <v-btn @click="toggleClass" depressed :disabled="false" class="white--text" color="blue2">
                  {{ readMore ? $t("Hide") : $t("Read more") }}
                </v-btn>
              </span>
            </section>
          </v-col>

          <v-col cols="12" md="3" class="px-16 col-class">
            <section class="card-content-parent">
              <h2 class="py-8 px-9">{{ $t('Suggestions') }}</h2>
              <div class="card-content pa-0 ma-0">
                <v-card @click="show(cat)" class="category-card rounded-lg shadow-none mb-6 mx-auto" width="320"
                  height="240" v-for="cat in categories" :key=cat.id>
                  <div class="overlay"></div>
                  <v-img :src="endPoint(cat?.image) + cat?.image" width="100%" class="image-zoom"></v-img>
                  <v-app-bar flat color="rgba(0, 0, 0, 0)" class="app-bar">
                    <span class="cat-type">{{ cat[`name_${$i18n.locale}`] }}</span>
                  </v-app-bar>
                  <v-card-text class="pa-0 ma-0 card-text shadow-none rounded-0">
                    <div class="font-weight-bold">
                      <h3 class="financial-consulting mt-2">{{ cat[`title_${$i18n.locale}`] }}</h3>
                      <p class="paragraph mt-7">{{ cat[`short_description_${$i18n.locale}`] }}</p>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </section>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" v-if="isLoading" :class="{ body_parent: isLoading }">
        <AnimatedLoading :isLoading="isLoading" :height="85" :hideText="true" />
      </v-col>
      <!-- Page Footer -->
      <v-col cols="12" class="page-footer">
        <v-card class="d-flex flex-column justify-center align-center" color="rgba(51, 204, 255, 0.20)" height="360">
          <h1 :class="checkScreenWidth && 'titleFontSizeMd'" class="title">
            {{ $t('Your partner in achieving your financial goals') }}
          </h1>
          <p :class="checkScreenWidth && 'fontSizeMd'" class="your-guide px-3 mt-8">
            "{{ $t('Get your guide to financial success') }}"
          </p>
          <v-btn depressed :class="checkScreenWidth ? 'pa-5' : 'pa-8'"
            class="white--text font-weight-bold mt-8 rounded-lg" color="primary" to="/consultations">
            <h3 :class="checkScreenWidth && 'fontSizeMd'"> {{ $t('Get Your Consultation Now') }}</h3>
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>

<script>

export default {
  name: "FinancialConsulting",
  data: () => ({
    form: {},
    isLoading: false,
    readMore: false,
    categories: [],
    items: [],

  }),
  mounted() {
    this.getSuggestionsData()
    this.getBlog()
    this.items = [
      {
        text: this.$i18n.t("Blog"),
        disabled: false,
        to: { path: "/blogs" },
      },

      {
        text: this.$i18n.t("Financial consulting"),
        disabled: true,
      },
    ];

  },
  computed: {
    checkScreenWidth() {
      return this.$vuetify.breakpoint.width < 1264;
    },
    blog() {
      return this.$store.state.blog || [];
    },
    endPoint() {
      return (imgLink) => {
        if (imgLink.startsWith('/')) {
          return this.$store.state.endpointURL.slice(0, -1)
        } else {
          return this.$store.state.endpointURL
        }
      };
    },
  },
  watch: {
    '$route.params.id'() {
      this.getBlog()
    }
  },

  methods: {
    getBlog() {
      this.isLoading = true;
      this.$api.GET_METHOD(`get_blog/${this.$route.params.id}`).then((response) => {
        this.isLoading = false;
        if (response.check) {
          this.form = response.data.data
        }
      })
    },

    getSuggestionsData() {
      this.isLoading = true;
      this.$api.GET_METHOD(`blogs`).then((response) => {
        this.isLoading = false;
        if (response.check) {
          this.categories = response.data.items
          // this.categories = response.data.items.filter(row => row.id != this.$route.params.id);
        }
      })
    },
    toggleClass() {
      this.readMore = !this.readMore;
    },
    show(card) {
      this.$router.push(`/financial-consulting/${card.id}`);
    },
  },

}
</script>

<style lang="scss" scoped>
.body_parent {
  height: 50dvh !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.financial-consulting-parent {
  @media (max-width: 600px) {
    .col-class {
      padding: 0 20px !important;
    }
  }

  .read-more {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 11;
    -webkit-box-orient: vertical;
    transition: transform 0.4s ease-in-out;
  }

  .body {


    .card-main-contenet {
      position: relative !important;

      .card-class {
        overflow: hidden;
      }

      .detail {
        padding: 3rem;
      }

      .app-bar {
        z-index: 2 !important;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.10);

        z-index: 1;
      }

      .main-image {
        position: absolute !important;
        width: 100%;
        height: 100%;
      }

      .cat-type {
        border-radius: var(--2, 8px);
        background: var(--Gray-12, #F9F9F9);
        box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.10);
        padding: var(--2, 8px) 12px;
        color: var(--Primery, #00294B);
        font-size: 18px;
        font-weight: 700;
        position: absolute !important;
        z-index: 2 !important;
      }

      .financial {
        color: var(--White, #FFF);
        font-size: 32px;
        font-weight: 700;
        position: absolute !important;
        z-index: 2 !important;
      }



    }

    .card-content-parent {
      border-radius: var(--3, 16px);
      background: var(--White, #FFF);
      box-shadow: 0px 9px 176px 0px rgba(0, 0, 0, 0.07);

      .card-content {
        overflow: auto;
        white-space: nowrap;
        max-height: 85dvh;


        &::-webkit-scrollbar {
          width: 2px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(--primary);
        }

        .category-card {
          cursor: pointer !important;
          overflow: hidden !important;
          position: relative;

          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.40);
            z-index: 1;
          }

          .image-zoom {
            transition: transform 0.4s ease;
            position: absolute !important;
            width: 100%;
            height: 100%;
          }

          &:hover .image-zoom {
            transform: scale(1.3);
          }

          .app-bar {
            z-index: 2 !important;
          }

          .cat-type {
            color: #00294B;
            font-size: 15px;
            font-weight: 700;
            border-radius: 8px;
            background: #F9F9F9;
            box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.10);
            padding: 5px 8px;
            position: absolute !important;
            z-index: 2 !important;
          }

          .card-text {
            height: 100%;
            padding: 15px 13px !important;
            color: #fff;
            transition: all .2s ease-in-out;
            position: absolute !important;
            z-index: 2 !important;

            .financial-consulting {
              color: var(--Gray-11, #E8E8EA);
              white-space: nowrap;
              font-size: 24px;
              font-weight: 700;
              line-height: 20px;
              z-index: 2 !important;
            }

            .paragraph {
              overflow: hidden;
              color: var(--Gray-11, #E8E8EA);
              font-size: 14px;
              font-weight: 700;
              line-height: 30px;
              overflow: hidden;
              display: -webkit-box;
              overflow: hidden;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }

          }

        }

      }

    }


  }

  .page-footer {
    .title {
      color: var(--Primery, #00294B) !important;
      font-weight: 700 !important;
      font-size: 45px !important;
      letter-spacing: 0.25px !important;
    }

    .titleFontSizeMd {
      font-size: 15px !important;
    }

    .your-guide {
      color: var(--Primery, #00294B) !important;
      text-align: center !important;
      font-size: 16px !important;
      font-weight: 700 !important;
      line-height: 32px !important;
      letter-spacing: 0.25px !important;
      max-width: 750px !important;
    }

    .fontSizeMd {
      font-size: 11px !important;
    }

  }


}
</style>