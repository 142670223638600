<template>
  <section id="requestSection" class="light">
    <v-container>
      <v-row>
        <!-- success -->
        <v-col cols="12" class="text-center mt-3">

          <v-card :min-height="$store.state.windowSize.y - 170"
            class="rounded-lg shadow d-flex align-center flex-column  mt-5 pa-5">

            <v-col class="subtitle-1 text-center" cols="12">
              <img src="@/assets/img/svg/failed.svg" alt="">
              <h3 class="text-h6 font-weight-bold red3--text">
                {{ $t('Something is wrong') }}</h3>
              <h3 class="text-h6 font-weight-bold red3--text mt-3">
                {{ $t('Dont worry, make a mistake, try again') }}
              </h3>
              <v-btn class="white--text blue2 mx-3 mt-5" min-width="130" elevation="0" :to="`/consultations`">
                {{ $t('Try again') }}
              </v-btn>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
 
  </section>
</template>

<script>
export default {
  name: "ConsultationRequestSuccess",
  data: () => ({
    time: null,
    date: null,
  }),
  components: {
  },
  mounted() {
    this.time = this.$router.currentRoute.query.time
    this.date = this.$router.currentRoute.query.date
    console.log('date',this.date);
  },
  computed: {

  },
  watch: {

  },
  props: {

  },
  methods: {

  },

};
</script>

<style lang="scss" scoped>
#requestSection {
  position: relative !important;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 70px;

  .v-card {
    .v-sheet {
      transition: 0.3s;
      cursor: pointer;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;

      &:hover {
        border-bottom: 5px solid var(--blue9);
      }
    }
  }

  button:not(.v-btn) {
    width: 100%;
    margin-top: 15px;
    height: 40px;
    text-align: start;
    padding: 5px 10px;
    border-radius: 8px;
    transition: 0.3s;

    i {
      color: #000;
    }

    &:hover {
      background-color: var(--blue2) !important;
      color: #fff;

      i {
        color: #fff;
      }
    }
  }

  .paymentMethods {
    display: flex;
    justify-content: center;
    align-content: center;
    border-radius: 0;
  }
}
</style>
