// Website Module Routes:-
import HomeModule from "@/views/Website/Home/HomeModule";

import ConsultationPage from "@/views/Website/ConsultationPage/ConsultationPage";

import BlogPage from "@/views/Website/BlogPage/BlogPage";
import FinancialConsulting from "@/views/Website/BlogPage/FinancialConsulting";


import ConsultationRequestPage from "@/views/Website/ConsultationRequest/ConsultationRequestPage";
import ConsultationRequestSuccess from "@/views/Website/ConsultationRequest/ConsultationRequestSuccess";
import ConsultationRequestFailed from "@/views/Website/ConsultationRequest/ConsultationRequestFailed";

import AuthenticationPage from "@/views/Website/Authentication/AuthenticationPage";
import ProfilePage from "@/views/Website/ProfilePage/ProfilePage";



export const WebsiteModuleRoutes = [
  {
    path: "/",
    name: "HomeModule",
    component: HomeModule,
    meta: {
      title: "home",
    },
  },
  {
    path: "/consultations",
    name: "ConsultationPage",
    component: ConsultationPage,
    meta: {
      title: "ConsultationPage",
    },
  },
  // BlogPage
  {
    path: "/blogs",
    name: "BlogPage",
    component: BlogPage,
    meta: {
      title: "BlogPage",
    },
  },

  {

    path: "/financial-consulting/:id",
    name: "FinancialConsulting",
    component: FinancialConsulting,
    meta: {
      title: "FinancialConsulting",

    },
  },

  {

    path: "/consultation-request/:id/:name",
    name: "ConsultationRequest",
    component: ConsultationRequestPage,
    meta: {
      title: "ConsultationRequest",

    },
  },
  {

    path: "/payment-success",
    name: "ConsultationRequestSuccess",
    component: ConsultationRequestSuccess,
    meta: {
      title: "ConsultationRequest",

    },
  },
  {

    path: "/payment-failed",
    name: "ConsultationRequestFailed",
    component: ConsultationRequestFailed,
    meta: {
      title: "ConsultationRequest",

    },
  },

  {
    path: "/profile",
    name: "ProfilePage",
    component: ProfilePage,
    meta: {
      title: "ProfilePage",
    },
  },
  {
    path: "/authentication",
    name: "Authentication",
    component: AuthenticationPage,
    meta: {
      title: "Authentication",
    },
  },
];
