import Vue from "vue";
import Vuex from "vuex";
import Settings from "./Settings";
import axios from "axios";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // endpointURL: "https://api.valuesolutionfc.net/", //  Live
    endpointURL: "https://valuesolutions-api.cs-testing.com/", // Testing
    app_version: process.env.VUE_APP_VERSION,
    apiAccess:
      "xcVBybDQhtDRqPxFUkpe1JVsZqyGGBR1v1KqendQ2qcLbqXB9hgqin41uRJ3qGTwD0L1hmRS29a0gXbKf8EBVcBy7ofvhKviDzJ88u8p6Hs9mNncXZSXOxeSQ7Ka7tlEhCnBMFtVSgZbuYf9sBubou5pWBxu1okFMC2ksCfk3jxdTQmeHq5xBlOL3pmkqq32L26LX1EB",
    isAuth: false,
    splashScreen: true,
    offline: false,
    language: localStorage.getItem("language") == "en" ? "en" : "ar",
    sidebarIsOpen: false,
    accessToken: null,
    activeSection: 1,
    isHomeSection: false,
    isAboutSection: false,
    isServicesSection: false,

    isContactSection: false,
    social_media: [],
    consultations: [],
    blog: [],
    sections: {
      header: null,
      slides: null,
      cases: null,

    },
    seo: [],
    userData: {},
    requiredRule: [(v) => !!v || Vue.prototype.i18n.t('field is required')],
    notRequiredRule: [],
    windowSize: { x: 0, y: 0, offsetTop: 0 },
    showSnackbar: false,
    snackbarType: 'info',
    snackbarTime: 4500,
    snackbarTitle: null,
    snackbarMessages: [],
  },
  mutations: {
    SET_DEFAULTS_DATA(state, data) {
      console.log('data', data);

      state.sections = {
        header: data.header || {},
        slides: data.slides || [],
        cases: data.cases || {},
        services_header: data.services_header || [],
        services: data.services || [],
        about_us: data.about_us[0] || {},
        contact_us: data.contact_us[0] || {},
        footer: data.footer || {},
      };
      state.consultations = data.consultations || []
      state.blog = data.blog || []
      state.splashScreen = false

    },
  },
  getters: {

  },
  actions: {
    checkAuth({ state }) {
      // state.splashScreen = true;
      // this.$axios.defaults.config.baseURL = ''
      state.isAuth = localStorage.getItem(
        Vue.prototype.$global.CRYPT("cloud", "access-token")
      )
        ? true
        : false;
      if (!state.isAuth) {
        //  state.splashScreen = false;
        // this.app.router.push('/login')
      } else {
        state.accessToken = Vue.prototype.$global.DECRYPT(
          "cloud",
          localStorage.getItem(
            Vue.prototype.$global.CRYPT("cloud", "access-token")
          )
        );
        axios.defaults.headers.common.Authorization =
          "Bearer " + state.accessToken;
        // state.isAuth = true
      }
    },
    getUserData({ state }) {
      if (!state.userData.api_token)
        Vue.prototype.$api.GET_METHOD(`get_user_profile`).then((response) => {
          if (response.check) {
            console.log('response.data', response.data);
            Vue.prototype.$api.SET_AUTH(response.data);
          }
        });
    },

    checkAppLanguage({ state }) {
      return state.language;
    },
    checkLang({ state }) {
      Vue.prototype.$i18n.locale = localStorage.getItem('language') ? localStorage.getItem('language') : 'ar';
      state.language = localStorage.getItem('language') ? localStorage.getItem('language') : 'ar';
      state.Settings.language = localStorage.getItem('language') ? localStorage.getItem('language') : 'ar';
    },
    getWebsiteData({ state, commit }) {
      axios.defaults.baseURL = state.endpointURL + `api/v1/${state.Settings.language}/front/`;
      setTimeout(() => {
        // state.splashScreen = true;
        Vue.prototype.$api.GET_METHOD(`home`).then((response) => {
          if (response.check) {
            commit("SET_DEFAULTS_DATA", response.data);
          }
        })
      }, 1);
    },
  },

  modules: { Settings },
});
