<template>
  <section class="my-n2 pa-0 ">
    <iframe width="100%" height="450" frameborder="0" style="border:0" :src="mapLink" allowfullscreen>
    </iframe>
  </section>
</template>

<script>



export default {
  name: "GoogleMap",
  data: () => ({
    mapLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14490.143443864798!2d46.688057099999995!3d24.7770981!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2efd2bfb54c1a7%3A0x1771b3512d9b8561!2z2KfYs9ix2KfYsSDYp9mE2LPYrdin2KjYqSDZhNiq2YLZhtmK2Kkg2KfZhNmF2LnZhNmI2YXYp9iqIC0gQ2xvdWQgU2VjcmV0cyBmb3IgaW5mb3JtYXRpb24gdGVjaG5vbG9neQ!5e0!3m2!1sen!2seg!4v1714303465617!5m2!1sen!2seg'
  }),
  computed: {},
  props: {},

};
</script>

<style lang="scss" scoped></style>
